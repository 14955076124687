import React from 'react';
import styled from 'styled-components';

const StyledFooter = styled.footer`
  grid-row-start: 3;
  grid-row-end: 4;
  width: 100%;
  display: flex;
  justify-content: center;
  color: gray;
  font-size: 1rem;
`;

const Footer = () => (
  <StyledFooter>
    <div>&copy; 2019 13jam, llc. All rights reserved.</div>
  </StyledFooter>
);

export default Footer;
