import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const StyledHeader = styled.header`
  width: 100%;
`;

const StyledNav = styled.nav`
  ul {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    list-style: none;
  }
  li {
    padding: 0.5em 4em 0.5em 4em;
  }
`;

const Header = () => (
  <StyledHeader>
    <StyledNav>
      <ul>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/about">About</Link>
        </li>
      </ul>
    </StyledNav>
  </StyledHeader>
);

export default Header;
