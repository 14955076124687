import React from 'react';
import styled from 'styled-components';

import MainLayout from '../Layouts/Main';

const StyledCenter = styled.div`
  text-align: center;
  margin: auto;
  h1 {
    font-size: 4rem;
  }
`;

function AboutPage() {
  return (
    <MainLayout>
      <StyledCenter>
        <h1>about 100yearhomepage.com</h1>
      </StyledCenter>
    </MainLayout>
  );
}

export default AboutPage;
