import React from 'react';
import styled from 'styled-components';

import MainLayout from '../Layouts/Main';

const StyledCenter = styled.div`
  text-align: center;
  margin: auto;
  h1 {
    font-size: 3rem;
  }
`;

function HomePage() {
  return (
    <MainLayout>
      <StyledCenter>
        <h1>100 Year Homepage</h1>
      </StyledCenter>
    </MainLayout>
  );
}

export default HomePage;
